import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ReactMarkdown from "react-markdown"

const TextCard = ({ title, reference, backLink = "/" }) => {
  const [content, setContent] = useState("Loading...")

  useEffect(() => {
    fetch(reference)
      .then((response) => response.text())
      .then((textContent) => setContent(textContent))
  }, [reference])

  return (
    <div className="textContainer">
      <Link to={backLink}>BACK</Link>

      <h1>{title}</h1>
      <div className="card markdown">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  )
}

export default TextCard
