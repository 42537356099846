import { Link, Routes, Route } from "react-router-dom"

import "./App.css"

import AppList, { POLICIES } from "./apps/list"
import PostList, { POSTS } from "./blog/list"

import TextCard from "./text_card"

const SUBTITLES = [
  "Quality software that no one asked for.",
  "Enterprise level development, potato level ideas.",
  "Lovingly handcrafted on MacBook Pro.",
  "Fine cuts of average code.",
  "Highly performant, rarely utilized.",
  "YANGI for mobile.",
  "Truly one of the developers of all time.",
]

const randomFromArray = (arr) => arr[Math.floor(Math.random() * arr.length)]

const Header = () => (
  <div className="header">
    <Link to="/">
      <img src="/favicon.ico" width="80" />
    </Link>

    <Link to="/">
      <div className="titleContainer">
        <div className="title">CHERRYNZ</div>
        <div className="subtitle">{randomFromArray(SUBTITLES)}</div>
      </div>
    </Link>

    <Link to="/blog" className="menu-item">
      Blog
    </Link>
  </div>
)

const Footer = () => (
  <div className="footer">
    <div className="footer-card">
      <a
        className="contact-link"
        href="mailto:contact@cherrynz.net"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src="/email.svg" className="icon" />
        CONTACT@CHERRYNZ.NET
      </a>
    </div>
  </div>
)

const App = () => (
  <div>
    <Header />

    <div className="container">
      <Routes>
        <Route path="/" element={<AppList />} />
        {POLICIES.map(({ policy: { path, reference } }, key) => (
          <Route
            {...{
              key,
              path,
              element: <TextCard {...{ title: "Privacy Policy", reference }} />,
            }}
          />
        ))}

        <Route path="/blog" element={<PostList />} />
        {POSTS.map(({ id, title, reference }) => (
          <Route
            {...{
              key: id,
              path: `/blog/${id}`,
              element: (
                <TextCard {...{ title, reference, backLink: "/blog" }} />
              ),
            }}
          />
        ))}
      </Routes>
    </div>

    <Footer />
  </div>
)

export default App
