import { Link } from "react-router-dom"

export const POSTS = [
  {
    id: "forked-dependencies",
    title: "Forked Dependency Fiasco",
    subtitle:
      "The painful unpicking of a forked dependency and my reasons for avoiding them in future",
    reference: require("./posts/forked.txt"),
    created: "2023-12-21",
  },
]

const List = () => {
  return (
    <div className="textContainer">
      {POSTS.map(({ id, title, subtitle, created }) => (
        <Link to={`/blog/${id}`} className="card blogCard">
          <div>
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            <div className="subtitle">Written {created}</div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default List
