import React from "react"
import { Link } from "react-router-dom"

import "./apps.css"

const buildPolicy = (name) => ({
  path: `${name}-privacy-policy`,
  reference: require(`./policies/${name}.txt`),
})

const APPS = [
  {
    icon: "cooked-logo.png",
    color: "#132936",
    googlePlay: "com.scottwillan.cookedas",
    ios: "cookedas/id6459789871",
    collaboration: {
      name: "Scott Willan",
      link: "https://play.google.com/store/apps/developer?id=Scotto",
    },
    policy: { path: "https://www.cookedas.com/privacy-policy", internal: true },
    links: [
      {
        title: "Official Site",
        link: "https://www.cookedas.co.nz",
      },
      {
        title: "Use the App Online",
        link: "https://app.cookedas.co.nz",
      },
    ],
  },
  {
    icon: "unprompted.png",
    color: "#333",
    googlePlay: "com.cherrynz.Unprompted",
    policy: buildPolicy("unprompted"),
    links: [],
  },
]

export const POLICIES = APPS.filter(({ policy: { internal } }) => !internal)

const CollaborationTag = ({ collaboration }) => {
  if (!collaboration) return null

  const { name, link } = collaboration

  return (
    <div className="collaboration-container">
      <a href={link}>Co-authored with {name}</a>
    </div>
  )
}

const Overlay = ({ collaboration, links }) => (
  <div className="icon-container overlay">
    {links.map(({ title, link }, key) => (
      <a className="external-link" href={link} key={`link-${key}`}>
        {title}
      </a>
    ))}

    <CollaborationTag {...{ collaboration }} />
  </div>
)

const PolicyLink = ({ policy }) => {
  if (!policy) return null

  if (policy.internal) {
    return (
      <div className="policyLink">
        <a href={policy.path}>Privacy Policy</a>
      </div>
    )
  }

  return (
    <div className="policyLink">
      <Link to={policy.path}>Privacy Policy</Link>
    </div>
  )
}

const GoogleLink = ({ googlePlay }) => {
  if (!googlePlay) return null

  return (
    <div className="storeButton">
      <a
        href={`https://play.google.com/store/apps/details?id=${googlePlay}`}
        className="googleLink"
      >
        <img
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    </div>
  )
}

const IosLink = ({ ios }) => {
  if (!ios) return null

  return (
    <div className="storeButton">
      <a
        href={`https://apps.apple.com/us/app/${ios}?itsct=apps_box_badge&amp;itscg=30200`}
        className="iosLink"
      >
        <img
          alt="Download on the App Store"
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1692835200"
        />
      </a>
    </div>
  )
}

const App = ({
  icon,
  color,
  googlePlay,
  ios,
  policy,
  collaboration,
  links,
}) => {
  return (
    <div className="card app">
      <div className="icon-container" style={{ backgroundColor: color }}>
        <Overlay {...{ collaboration, links }} />
        <img src={`./app_icons/${icon}`} className="icon" />
      </div>

      <div className="links">
        <div className="storeLinks">
          <GoogleLink {...{ googlePlay }} />
          <IosLink {...{ ios }} />
        </div>
        <PolicyLink {...{ policy }} />
      </div>
    </div>
  )
}

const List = () => (
  <>
    <div className="list">
      {APPS.map((app, index) => (
        <App {...app} key={`app-${index}`} />
      ))}
    </div>
  </>
)

export default List
